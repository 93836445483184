<template>
  <div :key="'r1' + count" style="margin: 20px;">
    <h3>
      {{ header_name }} Resolutions
      <el-tooltip :key="'r2' + count" content="New Resolution" placement="top">
        <a type="text" style="font-size: 14px; color: green;" @click="add()">
          New <i class="fas fa-plus-square"/>
        </a>
      </el-tooltip>
    </h3>
    <!--
    <el-row>
      <el-alert
        v-if="request_data_add > 0"
        :title="'(' + request_data_add + ') New resolutions added. Click refresh button to reload.'"
        type="success"
        show-icon
      />
    </el-row>
    <el-row>
      <el-alert
        v-if="request_data_update > 0"
        :title="'(' + request_data_update + ') Resolutions updated. Click refresh button to reload.'"
        type="success"
        show-icon
      />
    </el-row>
    -->
    <el-card shadow="never">
      <el-row>
        <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="20">
          <el-form :inline="true" size="small" label-position="right">
            <el-form-item>
              <el-select v-model="reso_type_s" placeholder="Type" style="width: 140px" clearable>
                <el-option
                  v-for="select in select_resolutions_label"
                  :key="select.value"
                  :label="select.value"
                  :value="select.value">
                  {{ select.label }}
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-date-picker
                v-model="year_s"
                type="year"
                placeholder="Year"
                style="width: 100px;"
                clearable
              />
            </el-form-item>
            <el-form-item>
              <el-input v-model="reso_no_s" placeholder="Reso. No." style="width: 120px;" clearable />
            </el-form-item>
            <el-form-item>
              <el-input v-model="title_s" placeholder="Title" style="width: 300px;" clearable />
            </el-form-item>
            <el-form-item>
              <el-tooltip content="Search" placement="top">
                <el-button :icon="icon_search" type="primary" @click="search()" />
              </el-tooltip>
            </el-form-item>
            <el-form-item>
              <el-tooltip content="Refresh" placement="top">
                <el-button :icon="icon_refresh" type="info" @click="refresh()" />
              </el-tooltip>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4" align="right">
          <el-form :inline="true" label-position="right">
            <el-form-item>
              <el-checkbox v-model="naf">No Attached File</el-checkbox>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-row>
        <el-table
          v-loading="loading"
          ref="resolutionsTable"
          :data="resolutions"
          size="mini"
          style="width: 100%; font-size: 14px;"
          highlight-current-row>
          <el-table-column type="expand" label="..." width="48">
            <template slot-scope="props">
              <p class="para"><strong>Type: </strong>{{ getResolutionLabel(props.row.reso_type).label }}</p>
              <p class="para"><strong>Year: </strong>{{ props.row.year }}</p>
              <p class="para"><strong>Resolution No.: </strong>{{ props.row.resolution_no }}</p>
              <p style="word-break: break-word;" class="para"><strong>Title: </strong>{{ props.row.title }}</p>
              <p v-if="props.row.minute_id" style="word-break: break-word;" class="para">
                <strong>Minutes: </strong>{{ props.row.minutes.title }}
              </p>
              <p v-if="props.row.doc_code" class="para"><strong>Document Code: </strong>{{ props.row.doc_code }}</p>
              <p v-if="props.row.rev_num" class="para"><strong>Revision No.: </strong>{{ props.row.rev_num }}</p>
              <p v-if="props.row.imp_date" class="para">
                <strong>Implementation Date: </strong>{{ getFormattedDate(props.row.imp_date, 'MMMM DD, Y') }}
              </p>
              <p v-if="props.row.con_num" class="para"><strong>Control No.: </strong>{{ props.row.con_num }}</p>
              <p v-if="props.row.add_by_personnel" class="para">
                <strong>Added By: </strong>
                <el-tooltip
                  :key="'r3' + props.row.id"
                  :content="getFormattedDate(props.row.created_at, 'MMMM DD, Y | hh:mm:ss a')"
                  placement="right">
                  <span style="cursor: default;">{{ props.row.add_by_personnel.name }}</span>
                </el-tooltip>
              </p>
              <p v-if="props.row.update_by_personnel" class="para">
                <strong>Updated By: </strong>
                <el-tooltip
                  :key="'r4' + props.row.id"
                  :content="getFormattedDate(props.row.updated_at, 'MMMM DD, Y | hh:mm:ss a')"
                  placement="right">
                  <span style="cursor: default;">{{ props.row.update_by_personnel.name }}</span>
                </el-tooltip>
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="reso_type" label="Type" width="150" />
          <el-table-column prop="year" label="Year" width="100" />
          <el-table-column prop="resolution_no" label="Reso. No." width="100" />
          <el-table-column label="Title" width="900">
            <template slot-scope="scope">
              <span v-if="scope.row.title.length < 120" style="word-break: break-word"> {{ scope.row.title }} </span>
              <span v-else style="word-break: break-word"> {{ scope.row.title.substring(0, 120) + " ..." }} </span>
            </template>
          </el-table-column>
          <el-table-column width="" align="center">
            <template slot-scope="scope">
              <div v-if="scope.row.path" style="word-break: break-word">
                <el-tag
                  type="success"
                  effect="plain"
                  size="medium"
                  style="color: #4CAF50 !important; border-color: #4CAF50 !important; width: 120px;">
                  File Uploaded
                </el-tag>
              </div>
              <div v-else style="word-break: break-word">
                <el-tag type="danger" effect="plain" size="medium" style="width: 120px;">No Attached File</el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="Operation (s)" width="150" align="right">
            <template slot-scope="scope">
              <el-button-group>
                <el-tooltip
                  v-if="scope.row.path"
                  :key="'r5' + scope.row.id"
                  content="View Attached File"
                  placement="top">
                  <el-button
                    :key="'r6' + scope.row.id"
                    type="primary"
                    icon="el-icon-document"
                    size="mini"
                    style="margin-right: 10px;"
                    @click="viewFile(scope.row.path)"
                  />
                </el-tooltip>
                <el-tooltip :key="'r7' + scope.row.id" content="Edit Resolution" placement="top">
                  <el-button
                    :key="'r8' + scope.row.id"
                    type="primary"
                    icon="el-icon-edit"
                    size="mini"
                    style="margin-right: 10px;"
                    @click="edit(scope.row)"
                  />
                </el-tooltip>
              </el-button-group>
            </template>
          </el-table-column>
        </el-table>
        <div align="center" style="margin-top: 10px;">
          <el-pagination
            :page-size="meta.per_page"
            :pager-count="5"
            :total="meta.total"
            :current-page.sync="meta.page"
            layout="total, prev, pager, next"
            @current-change="getResolutions()"
          />
        </div>
      </el-row>
    </el-card>
    <el-dialog
      :visible.sync="editable"
      :title="title_dialog"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      top="5vh"
      width="75%">
      <el-row style="margin: -50px 0;">
        <el-divider />
        <p>
          <strong>Note:</strong>
          Fields marked with asterisk (<span style="color: #f56c6c; font-weight: bold;">*</span>) are required.
        </p>
        <el-form ref="selected" :model="selected" :rules="rules" size="small">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
              <el-form-item label="Type" prop="reso_type">
                <el-select
                  v-model="selected.reso_type"
                  :clearable="false"
                  placeholder="Please select type"
                  @change="selected.minute_id = null; minutes_file = null">
                  <el-option
                    v-for="select in select_resolutions_label"
                    :key="select.value"
                    :label="select.value"
                    :value="select.value">
                    {{ select.label }}
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Year" prop="year">
                <el-date-picker
                  v-model="selected.year"
                  :clearable="false"
                  type="year"
                  placeholder="Please pick a year"
                  style="width: 100%;"
                />
              </el-form-item>
              <el-form-item label="Resolution No." prop="resolution_no">
                <el-input
                  v-model="selected.resolution_no"
                  :clearable="false"
                  placeholder="Please input resolution no."
                  style="width: 100%;"
                />
              </el-form-item>
              <el-form-item label="Title" prop="title">
                <el-input
                  v-model="selected.title"
                  :clearable="false"
                  :autosize="{ minRows: 6 }"
                  type="textarea"
                  placeholder="Please input title"
                  style="width: 100%; word-break: keep-all;"
                />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
              <el-form-item>
                <div style="font-weight: bold;">Minutes</div>
                <el-row :gutter="10">
                  <el-col :xs="24" :sm="24" :md="21" :lg="21" :xl="21">
                    <select-minutes
                      v-if="editable && selected.reso_type"
                      :post.sync="selected.minutes"
                      :reso_type="selected.reso_type"
                      button_size="small"
                      style="width: 100%;"
                      @setMinutes="selected.minute_id = $event;"
                    />
                    <el-select
                      v-else
                      :disabled="true"
                      placeholder="Search Minutes"
                      value=""
                      style="width: 100%;"
                    />
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="3" :lg="3" :xl="3" align="right">
                    <el-button
                      v-if="!selected.minute_id"
                      :disabled="true"
                      icon="el-icon-document"
                      size="small"
                      style="width: 100%;"
                    />
                    <div v-loading="loading1" v-else>
                      <el-tooltip
                        v-if="minutes_file"
                        content="View Attached File"
                        placement="top">
                        <el-button
                          type="primary"
                          icon="el-icon-document"
                          size="small"
                          style="width: 100%;"
                          @click="viewFileMinutes(minutes_file)"
                        />
                      </el-tooltip>
                      <el-tooltip
                        v-else
                        content="No Attached File"
                        placement="top">
                        <el-button
                          :disabled="true"
                          type="danger"
                          icon="el-icon-document"
                          size="small"
                          style="width: 100%;"
                        />
                      </el-tooltip>
                    </div>
                  </el-col>
                </el-row>
                <span style="font-size: 11px; font-style: italic; color: #E6A23C;">
                  E.g. Minutes of the 39 BOR Meeting | April 03, 1981 | Guest House, ViSCA, Baybay, Leyte
                </span>
              </el-form-item>
              <el-form-item label="Document Code">
                <el-input
                  v-model="selected.doc_code"
                  placeholder="Please input document code"
                  style="width: 100%;"
                  clearable
                />
              </el-form-item>
              <el-form-item label="Revision No.">
                <el-input
                  v-model.number="selected.rev_num"
                  type="number"
                  min="1"
                  placeholder="Please input revision no."
                  style="width: 100%;"
                  clearable
                />
              </el-form-item>
              <el-form-item label="Implementation Date">
                <el-date-picker
                  v-model="selected.imp_date"
                  type="date"
                  placeholder="Please input implementation date"
                  style="width: 100%;"
                  clearable
                />
              </el-form-item>
              <el-form-item label="Control No.">
                <el-input
                  v-model="selected.con_num"
                  placeholder="Please input control no."
                  style="width: 100%;"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
              <el-form-item label="Attached File">
                <resolution-uploader-component
                  v-if="editable"
                  :path.sync="selected.path"
                  class="my-upload-job"
                  @setAttachment="selected.path = $event"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-divider />
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" @click="submitForm('selected')">Save</el-button>
        <el-button size="small" @click="submitClose('selected')">Cancel</el-button>
      </span>
      <el-dialog
        :visible.sync="view_attach_form_minutes"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="closeFileMinutes"
        :append-to-body="true"
        width="60%"
        top="5vh">
        <div>
          <embed :key="view_file_minutes" :src="view_file_minutes" style="width: 100%; height: 700px;">
        </div>
      </el-dialog>
    </el-dialog>
    <el-dialog
      :visible.sync="view_attach_form"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="closeFile"
      width="60%"
      top="5vh">
      <div>
        <embed :key="view_file" :src="view_file" style="width: 100%; height: 700px;">
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Resolutions } from '@/api/records/resolutions'
import { Notification } from 'element-ui'
import ResolutionUploaderComponent from '@/views/layout/components/Records/ResolutionUploaderComponent'
import SelectMinutes from '@/views/layout/components/Records/SelectMinutes'

export default {
  name: 'Resolutions',
  components: { ResolutionUploaderComponent, SelectMinutes },
  // props: {
  //   reso_type: {
  //     type: String,
  //     default: null,
  //     required: true
  //   }
  // },
  data() {
    return {
      loading: false,
      meta: { page: 1 },
      resolutions: [],
      editable: false,
      title_dialog: null,
      select_resolutions_label: [
        { value: 'BOR', label: 'Board of Regents (BOR)' },
        { value: 'Special BOR', label: 'Special Board of Regents (SBOR)' },
        { value: 'UAC', label: 'University Academic Council (UAC)' },
        { value: 'UADCO', label: 'University Administrative Council (UADCO)' }
      ],
      year_s: null,
      reso_type_s: null,
      title_s: null,
      naf: false,
      path_s: null,
      reso_no_s: null,
      selected: {
        reso_type: null,
        year: null,
        resolution_no: null,
        title: null,
        minute_id: null,
        doc_code: null,
        rev_num: null,
        imp_date: null,
        con_num: null,
        path: null
      },
      rules: {
        reso_type: [{ required: true, message: 'Resolution is required' }],
        year: [{ required: true, message: 'Year is required' }],
        resolution_no: [{ required: true, message: 'Resolution no. is required' }],
        title: [{ required: true, message: 'Title is required' }]
      },
      // request_data_add: 0,
      // request_data_update: 0,
      // cur_personnel_id: this.$store.getters.user.currentUser.id,
      base_url: this.$store.getters.base_url,
      icon_search: 'el-icon-search',
      icon_refresh: 'el-icon-refresh',
      view_file: null,
      view_attach_form: false,
      count: 0,
      header_name: null,
      loading1: false,
      minutes_file: null,
      view_file_minutes: null,
      view_attach_form_minutes: false
    }
  },
  watch: {
    naf: function() {
      this.year_s = null
      this.title_s = null
      this.reso_no_s = null
      this.reso_type_s = null
      if (this.naf === true) this.path_s = 1
      else this.path_s = null
      this.meta.page = 1
      this.getResolutions()
    },
    'selected.minute_id': function(id) {
      if (id) this.getMinutesFile(id)
      else this.selected.minute_id = null
      // console.log('goods!', this.selected.minute_id)
    }
  },
  created: function() {
    this.count++
    this.getResolutions()
  },
  // destroyed() {
  //   this.$echo.leave('resolutions')
  // },
  methods: {
    getResolutions() {
      this.loading = true
      // get = ResolutionsController@index
      Resolutions({
        page: this.meta.page,
        year: this.year_s,
        reso_type: this.reso_type_s,
        title: this.title_s,
        path: this.path_s,
        reso_no: this.reso_no_s
      }, 'get').then(res => {
        this.resolutions = res.data.resolutions
        this.meta = res.data.meta
        this.loading = false
        this.icon_search = 'el-icon-search'
        this.icon_refresh = 'el-icon-refresh'
      }).catch(error => {
        console.log(error)
        this.loading = false
      })
    },
    search() {
      this.icon_search = 'el-icon-loading'
      if (this.year_s !== null) this.year_s = this.getFormattedYear(this.year_s)
      this.meta.page = 1
      this.getResolutions()
    },
    refresh() {
      this.icon_refresh = 'el-icon-loading'
      this.reso_type_s = null
      this.year_s = null
      this.title_s = null
      this.path_s = null
      this.reso_no_s = null
      this.naf = false
      // this.request_data_add = 0
      // this.request_data_update = 0
      this.meta.page = 1
      this.getResolutions()
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.save()
        } else {
          // Notification.error({
          //   title: 'Error',
          //   message: 'Unable to save',
          //   duration: 5 * 1000
          // })
          return false
        }
      })
    },
    submitClose(formName) {
      this.$refs[formName].resetFields()
      this.selected = {
        reso_type: null,
        year: null,
        resolution_no: null,
        title: null,
        minute_id: null,
        doc_code: null,
        rev_num: null,
        imp_date: null,
        con_num: null,
        path: null
      }
      this.minutes_file = null
      this.editable = false
    },
    viewFile(path) {
      this.view_file = this.base_url + '/records/resolutions/download/' + path
      this.view_attach_form = true
    },
    closeFile() {
      this.view_file = null
      this.view_attach_form = false
    },
    add() {
      this.title_dialog = 'New Resolution'
      this.selected.orig_path = null
      this.editable = true
      // console.log('add -> this.selected:', this.selected)
    },
    edit(row) {
      this.title_dialog = 'Edit Resolution'
      this.selected = Object.assign({}, row)
      this.selected.year = "'" + this.selected.year + "'"
      this.selected.orig_path = this.selected.path
      // console.log('edit -> this.selected:', this.selected)
      this.editable = true
    },
    save: function() {
      this.selected.year = this.getFormattedYear(this.selected.year)
      if (this.selected.imp_date) this.selected.imp_date = this.getFormattedDate(this.selected.imp_date)
      // update = ResolutionsController@update
      // create = ResolutionsController@store
      var action = this.selected.id ? 'update' : 'create'
      Resolutions(this.selected, action).then(res => {
        if (res.data.error === true) {
          Notification.error({
            title: 'Error',
            message: 'Data already existed',
            duration: 5 * 1000
          })
        } else {
          Notification.success({
            title: 'Success',
            message: 'Resolution has been saved successfully',
            duration: 5 * 1000
          })
          this.submitClose('selected')
          this.getResolutions()
        }
      }).catch(error => {
        console.log(error)
      })
    },
    getFormattedYear(year) {
      return this.$moment(new Date(year)).format('YYYY')
    },
    getFormattedDate(date, format = 'YYYY-MM-DD') {
      return this.$moment(date).format(format)
    },
    getResolutionLabel(type) {
      return this.select_resolutions_label.find((types) => types.value === type)
    },
    getMinutesFile(id) {
      // console.log('getMinutesFile:', id)
      this.loading1 = true
      // get = ResolutionsController@getMinutesFile
      Resolutions({ id: id }, 'get_minutes_file').then(res => {
        this.minutes_file = res.data.minutes_file.path
        this.loading1 = false
        // console.log('this.minutes_file.path:', this.minutes_file)
      }).catch(error => {
        console.log(error)
        this.loading1 = false
      })
    },
    viewFileMinutes(path) {
      this.view_file_minutes = this.base_url + '/records/minutes/download/' + path
      this.view_attach_form_minutes = true
    },
    closeFileMinutes() {
      this.view_file_minutes = null
      this.view_attach_form_minutes = false
    }
    // handleCurrentChange(val) {
    //   this.currentRow = val
    // }
  }
}
</script>
