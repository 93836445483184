<template>
  <el-select
    v-model="minutes_obj"
    :remote-method="searchMinutes"
    :remote="true"
    :loading="loading"
    :size="button_size"
    placeholder="Search Minutes"
    clearable
    filterable
    style="width: 100%;"
    @change="updateParent">
    <el-option
      v-for="minute in minutes"
      :key="minute.id"
      :value="minute.id"
      :label="minute.title"
    />
  </el-select>
</template>

<script>
import { Minutes } from '@/api/records/minutes'

export default {
  name: 'SelectMinutes',
  props: {
    post: {
      type: Object,
      default: null
    },
    reso_type: {
      type: String,
      default: null
    },
    button_size: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      minutes: [],
      minutes_obj: this.post
    }
  },
  watch: {
    post: function(item) {
      this.minutes = [item]
      this.minutes_obj = item ? [item] : []
      // console.log('watch -> post:', this.post)
      // console.log('watch -> minutes:', this.minutes)
      // console.log('watch -> minutes_obj:', this.minutes_obj)
    }
  },
  created() {
    this.minutes = this.minutes.filter(function(element) {
      return element !== undefined
    })
    if (this.post) {
      this.minutes = [this.post]
      this.minutes_obj = this.post
    }
    // console.log('created() -> this.reso_type:', this.reso_type)
    // console.log('created() -> this.post:', this.post)
    // console.log('created() -> this.minutes:', this.minutes)
    // console.log('created() -> this.minutes_obj:', this.minutes_obj)
  },
  methods: {
    updateParent(id) {
      this.$emit('setMinutes', id)
      // console.log(id)
    },
    searchMinutes(minutes) {
      if (minutes.length > 2) {
        this.loading = true
        setTimeout(() => {
          Minutes({
            reso_type: this.reso_type,
            minutes: minutes
          }, 'search_minutes').then(res => {
            this.minutes = res.data.minutes
            this.loading = false
          }).catch((error) => {
            console.log(error)
          })
        }, 100)
      }
    }
  }
}

</script>

